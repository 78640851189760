<template>
  <div>
    <search-traffic-pool-usage-orders
      @submit="submitSearch"
    />

    <div class="iotplt-table-operation-buttons">
      <a-space>
        <a-button type="primary" @click="exportData">导出</a-button>
      </a-space>
    </div>

    <a-table
      size="middle"
      :scroll="{ x: 'max-content' }"
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      @change="sortChange"
      row-key="month"
    />

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />

  </div>
</template>

<script>
import { findTrafficPoolUsageOrders, exportTrafficPoolUsageOrders } from '@/api/traffic_pool'
import Pagination from '@/components/Pagination'
import SearchTrafficPoolUsageOrders from '@/views/traffic_pools/usage_orders/Search'
import { formatBigNumber, formatCurrency } from '@/utils/filter'
import { exportExcel } from '@/api/excel'

export default {
  name: 'TrafficPoolUsageOrdersList',
  components: {
    SearchTrafficPoolUsageOrders,
    Pagination
  },
  data() {
    return {
      data: [],
      loading: true,
      pagination: {
        total_count: 0
      },
      query: {},
      sort: {
        sort_field: '',
        sort_order: ''
      }
    }
  },
  created() {
    this.fetchData()
  },
  computed: {
    columns() {
      return [
        {
          title: '时间',
          dataIndex: 'month',
          fixed: 'left',
          sorter: true,
          sortOrder: this.sort.sort_field === 'month' ? this.sort.sort_order : false
        },
        {
          title: '状态',
          dataIndex: 'status'
        },
        {
          title: '活跃卡号总数',
          customRender: formatBigNumber,
          dataIndex: 'activated_cards_count'
        },
        {
          title: '流量池额定流量',
          dataIndex: 'capacity'
        },
        {
          title: '已用流量',
          dataIndex: 'used'
        },
        {
          title: '超额用量',
          dataIndex: 'exceed_usage'
        },
        {
          title: '超额费用(元)',
          customRender: formatCurrency,
          dataIndex: 'exceed_fee'
        },
        {
          title: '总费用(元)',
          customRender: formatCurrency,
          dataIndex: 'total_fee'
        }
      ]
    },

    trafficPoolId() {
      return parseInt(this.$route.params.id)
    }
  },
  methods: {
    submitSearch(search) {
      // 重置排序
      this.sort = this.$options.data().sort
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        { per_page: this.query.per_page },
        search)
      this.fetchData()
    },

    sortChange(pagination, filters, sorter) {
      this.sort.sort_field = sorter.field
      this.sort.sort_order = sorter.order
      // 跳转第一页
      this.query.page = 1
      this.fetchData()
    },

    fetchData() {
      this.loading = true
      findTrafficPoolUsageOrders(this.trafficPoolId, Object.assign({}, this.query, this.sort)).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.pagination = res.pagination
        }
        this.loading = false
      })
    },

    exportData() {
      exportTrafficPoolUsageOrders(this.trafficPoolId, Object.assign({}, this.query)).then((res) => {
        if (res.code === 0 && res.data.is_direct_download === true) {
          exportExcel(res.data.id, res.data.file_name)
        }
      })
    }
  }
}
</script>
